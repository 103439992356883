<template>
  <b-form @submit.prevent="submitUpdate">
    <div class="card">
      <div class="card-body">
        <draggable
          v-model="items"
          class="list-group list-group-flush cursor-move my-0"
          tag="ul"
          @change="onChangeSort"
        >
          <b-list-group-item
            v-for="(item, index) in items"
            :key="index"
            tag="li"
            class="pl-0 pr-0 py-0 mb-50"
            style="border: 0px"
          >
            <app-collapse accordion type="margin">
              <app-collapse-item
                :title="`${index + 1} - ${item.title ? item.title : 'Item'} `"
              >
                <div class="form-row">
                  <div class="col-md-11">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="item.title"
                        placeholder="Título"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-md-1">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="removeItem(index)"
                      block
                    >
                      <i class="bi bi-x"></i>
                    </b-button>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-0">
                      <editor
                        class="description-editor-1"
                        api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                        v-model="item.description"
                        :init="editorConfig"
                      />
                    </div>
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
          </b-list-group-item>
        </draggable>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Adicionar</span>
        </b-button>
      </div>
    </div>

    <div class="row d-flex justify-content-end">
      <div class="col-md-3">
        <ButtonsActionsFooter
          variant="success"
          :submited="submited"
          text="Salvar"
          subtext="Aguarde..."
        />
      </div>
    </div>

    <p v-if="updating" class="mb-0te text-success">
      <b-spinner label="Loading..." small class="mr-1" />
      Atualizando...
    </p>
  </b-form>
</template>

<script>
import {
  BCard,
  BCol,
  BSpinner,
  BListGroupItem,
  BCardBody,
  BListGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BForm,
  BRow,
  BTabs,
  BTab,
} from "bootstrap-vue";

import draggable from "vuedraggable";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import Editor from "@tinymce/tinymce-vue";
export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      nextId: 0,
      policy: "",
      items: [],
      updating: false,
      submited: false,
      editorConfig: {
        menubar: false,
        colors: [
          "#00C390",
          "#4361EE",
          "#00C390",
          "#FAB005",
          "#009C73",
          "#00DDA3",
        ],
        toolbar:
          "undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image | template | forecolor backcolor | fullscreen",
      },
    };
  },
  components: {
    editor: Editor,
    AppCollapse,
    BSpinner,
    AppCollapseItem,
    BCard,
    BListGroupItem,
    BCardBody,
    BListGroup,
    draggable,
    BFormInput,
    BInputGroup,
    BButton,
    BRow,
    BForm,
    BTabs,
    BCol,
    BTab,
    ButtonsActionsFooter,
  },
  created() {
    this.getData();
  },
  methods: {
    submitUpdate() {
      this.$v.items.$touch();
      this.submited = true;

      if (this.items.length) {
        this.$store
          .dispatch("PrivacyPolicy/update", { items: this.items })
          .then(() => {
            this.notify(
              "Operação realizada com sucesso!",
              "UserCheckIcon",
              "success",
              ""
            );
          })
          .catch(() => {
            this.submited = false;
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async getData() {
      this.$store.dispatch("PrivacyPolicy/all").then((res) => {
        this.items = res;
      });
    },
    onChangeSort(event) {
      if (event.moved) {
        this.updating = true;
      }
    },
    repeateAgain() {
      this.items.push({
        id: this.nextId + this.items.length,
        title: "",
        description: "",
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
  },
  validations: {
    items: {
      title: {
        required,
      },
      description: {
        required,
      },
    },
  },
};
</script>
