var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitUpdate.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c(
              "draggable",
              {
                staticClass: "list-group list-group-flush cursor-move my-0",
                attrs: { tag: "ul" },
                on: { change: _vm.onChangeSort },
                model: {
                  value: _vm.items,
                  callback: function ($$v) {
                    _vm.items = $$v
                  },
                  expression: "items",
                },
              },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "b-list-group-item",
                  {
                    key: index,
                    staticClass: "pl-0 pr-0 py-0 mb-50",
                    staticStyle: { border: "0px" },
                    attrs: { tag: "li" },
                  },
                  [
                    _c(
                      "app-collapse",
                      { attrs: { accordion: "", type: "margin" } },
                      [
                        _c(
                          "app-collapse-item",
                          {
                            attrs: {
                              title:
                                index +
                                1 +
                                " - " +
                                (item.title ? item.title : "Item") +
                                " ",
                            },
                          },
                          [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-md-11" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.title,
                                        expression: "item.title",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Título",
                                    },
                                    domProps: { value: item.title },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          "title",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-1" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(234, 84, 85, 0.15)",
                                          expression:
                                            "'rgba(234, 84, 85, 0.15)'",
                                          modifiers: { 400: true },
                                        },
                                      ],
                                      attrs: {
                                        variant: "outline-danger",
                                        block: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeItem(index)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "bi bi-x" })]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group mb-0" },
                                  [
                                    _c("editor", {
                                      staticClass: "description-editor-1",
                                      attrs: {
                                        "api-key":
                                          "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                        init: _vm.editorConfig,
                                      },
                                      model: {
                                        value: item.description,
                                        callback: function ($$v) {
                                          _vm.$set(item, "description", $$v)
                                        },
                                        expression: "item.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "ripple",
                    rawName: "v-ripple.400",
                    value: "rgba(255, 255, 255, 0.15)",
                    expression: "'rgba(255, 255, 255, 0.15)'",
                    modifiers: { 400: true },
                  },
                ],
                attrs: { variant: "primary" },
                on: { click: _vm.repeateAgain },
              },
              [
                _c("feather-icon", {
                  staticClass: "mr-25",
                  attrs: { icon: "PlusIcon" },
                }),
                _c("span", [_vm._v("Adicionar")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row d-flex justify-content-end" }, [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c("ButtonsActionsFooter", {
              attrs: {
                variant: "success",
                submited: _vm.submited,
                text: "Salvar",
                subtext: "Aguarde...",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.updating
        ? _c(
            "p",
            { staticClass: "mb-0te text-success" },
            [
              _c("b-spinner", {
                staticClass: "mr-1",
                attrs: { label: "Loading...", small: "" },
              }),
              _vm._v(" Atualizando... "),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }